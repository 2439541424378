<template>
  <div class="help-page">
    <div class="employee__title">
      <PageTitle text="Помощь" />
    </div>
    <div class="help-page__tabs">
      <HelpTabs />
    </div>
  </div>
</template>

<script>
import PageTitle from '../components/elements/PageTitle'
import HelpTabs from '../components/blocks/HelpTabs'

export default {
  name: 'Help',
  components: {
    HelpTabs,
    PageTitle,
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/helpers/variables';
@import 'src/assets/styles/helpers/mixin';

.employee {
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 2.4rem;
  }
}
</style>
