<template>
  <div class="faq">
    <el-collapse v-model="activeNames">
      <div
        class="faq__col"
        v-if="faqListL && faqListL.length"
      >
        <el-collapse-item
          v-for="(item, index) in faqListL"
          :title="item.title"
          :name="`${String(index + 1)}_l`"
          :key="index"
        >
          <div v-html="item.content"></div>
        </el-collapse-item>
      </div>
      <div
        class="faq__col"
        v-if="faqListR && faqListR.length"
      >
        <el-collapse-item
          v-for="(item, index) in faqListR"
          :title="item.title"
          :name="`${String(index + 1)}_r`"
          :key="index"
        >
          <div v-html="item.content"></div>
        </el-collapse-item>
      </div>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
  data() {
    return {
      activeNames: ['1_l'],
      faqListL: [],
      faqListR: [],
    };
  },
  mounted() {
    const faqList = [
      {
        title: 'Проблемы с чатом',
        content: `
          <p><strong>Изменение пароля</strong></p>
          <ol>
            <li>Нажать на кнопку "Изменить пароль".</li>
            <li>Открыть модальное окно изменения пароля компании.</li>
            <li>Ввести пароль.</li>
            <li>Повторить ввод пароля.</li>
            <li>Нажать кнопку "Изменить".</li>
            <li>Закрыть модальное окно.</li>
          </ol>

          <p><strong>Редактирование информацию о компании</strong></p>
          <ol>
            <li>Нажать кнопку "Редактировать".</li>
            <li>Разблокировать поля формы о компании для редактирования.</li>
            <li>Заполнить поля формы. и нажать кнопку "Сохранить".</li>
          </ol>
        `
      },
      {
        title: 'Управление исполнителями',
        content: `
          <p><strong>Изменение пароля</strong></p>
          <ol>
            <li>Нажать на кнопку "Изменить пароль".</li>
            <li>Открыть модальное окно изменения пароля компании.</li>
            <li>Ввести пароль.</li>
            <li>Повторить ввод пароля.</li>
            <li>Нажать кнопку "Изменить".</li>
            <li>Закрыть модальное окно.</li>
          </ol>

          <p><strong>Редактирование информацию о компании</strong></p>
          <ol>
            <li>Нажать кнопку "Редактировать".</li>
            <li>Разблокировать поля формы о компании для редактирования.</li>
            <li>Заполнить поля формы. и нажать кнопку "Сохранить".</li>
          </ol>
        `
      },
      {
        title: 'Управление исполнителями',
        content: `
          <p><strong>Изменение пароля</strong></p>
          <ol>
            <li>Нажать на кнопку "Изменить пароль".</li>
            <li>Открыть модальное окно изменения пароля компании.</li>
            <li>Ввести пароль.</li>
            <li>Повторить ввод пароля.</li>
            <li>Нажать кнопку "Изменить".</li>
            <li>Закрыть модальное окно.</li>
          </ol>

          <p><strong>Редактирование информацию о компании</strong></p>
          <ol>
            <li>Нажать кнопку "Редактировать".</li>
            <li>Разблокировать поля формы о компании для редактирования.</li>
            <li>Заполнить поля формы. и нажать кнопку "Сохранить".</li>
          </ol>
        `
      },
      {
        title: 'Проблемы с чатом',
        content: `
          <p><strong>Изменение пароля</strong></p>
          <ol>
            <li>Нажать на кнопку "Изменить пароль".</li>
            <li>Открыть модальное окно изменения пароля компании.</li>
            <li>Ввести пароль.</li>
            <li>Повторить ввод пароля.</li>
            <li>Нажать кнопку "Изменить".</li>
            <li>Закрыть модальное окно.</li>
          </ol>

          <p><strong>Редактирование информацию о компании</strong></p>
          <ol>
            <li>Нажать кнопку "Редактировать".</li>
            <li>Разблокировать поля формы о компании для редактирования.</li>
            <li>Заполнить поля формы. и нажать кнопку "Сохранить".</li>
          </ol>
        `
      },
    ]
    this.splitList(faqList)
  },
  methods: {
    splitList(list) {
      list.forEach((l, index) => {
        if ((index + 1) % 2 === 0) {
          this.faqListR.push(l)
        } else {
          this.faqListL.push(l)
        }
      })
    }
  },
}
</script>

<style lang="scss">
@import 'src/assets/styles/helpers/mixin';
@import 'src/assets/styles/helpers/variables';

.faq {
  $space-h: 2.4rem;
  $space-v: 2.4rem;

  .el-collapse {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    border: none;
    margin: 0 -#{floor-float($space-h / 2, 1)} -#{$space-v};
  }

  &__col {
    $width: 50%;

    max-width: $width;
    width: $width;
    flex: 1 0 $width;
    padding: 0 #{floor-float($space-h / 2, 1)};

    @include below($xl) {
      $width: 100%;

      max-width: $width;
      width: $width;
      flex: 1 0 $width;
    }
  }

  .el-collapse-item {
    margin-bottom: $space-v;
  }

  .el-collapse-item__header {
    position: relative;
    height: auto;
    padding: 16px 54px 16px 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #31333b;
    border: none;
    background-color: #daebfb;
    backface-visibility: hidden;
    box-shadow: 2px 2px 16px rgba(70, 80, 119, 0.04);
    border-radius: 4px;
    transition: {
      property: color, background-color;
      duration: $transition-duration;
    }

    &:hover {
      background-color: #c4e3ff;
    }

    &.focusing:focus:not(:hover) {
      color: #1199f0;
    }
  }

  .el-collapse-item__arrow {
    position: absolute;
    top: 50%;
    right: $space-h;
    display: block;
    width: 2rem;
    height: 2rem;
    margin: -1rem 0;
    flex: 1 0;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      height: 2px;
      width: 100%;
      margin-top: -1px;
      border-radius: 1px;
      background-color: currentColor;
      transition: {
        property: background-color, transform;
        duration: $transition-duration;
      }
    }

    &::before {
      transform: rotate(90deg);
    }

    &.is-active {
      transform: rotate(0);

      &::before {
        transform: rotate(0deg);
      }
    }
  }

  .el-collapse-item__wrap {
    border: none;
    will-change: auto;
  }

  .el-collapse-item__content {
    padding: 0.4rem 0 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
    color: $basicBlack;
    background: none;

    & > div {
      padding: 1.9rem 2.4rem 2.1rem;
      background-color: #f2f9ff;
    }

    p {
      margin: 0;
    }

    ol {
      list-style: none;
      padding: 0;
      margin: 0 0 2.4rem;
      counter-reset: item;

      &:last-child {
        margin-bottom: 0;
      }

      & > li {
        counter-increment: item;

        &::before {
          position: relative;
          display: inline-block;
          content: counter(item);
          padding: 0 0.4rem 0 0;
        }
      }
    }
  }
}
</style>
