var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "faq"
  }, [_c('el-collapse', {
    model: {
      value: _vm.activeNames,
      callback: function callback($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, [_vm.faqListL && _vm.faqListL.length ? _c('div', {
    staticClass: "faq__col"
  }, _vm._l(_vm.faqListL, function (item, index) {
    return _c('el-collapse-item', {
      key: index,
      attrs: {
        "title": item.title,
        "name": "".concat(String(index + 1), "_l")
      }
    }, [_c('div', {
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    })]);
  }), 1) : _vm._e(), _vm.faqListR && _vm.faqListR.length ? _c('div', {
    staticClass: "faq__col"
  }, _vm._l(_vm.faqListR, function (item, index) {
    return _c('el-collapse-item', {
      key: index,
      attrs: {
        "title": item.title,
        "name": "".concat(String(index + 1), "_r")
      }
    }, [_c('div', {
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    })]);
  }), 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }