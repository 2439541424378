<template>
  <h1 class="page-title">
    {{ text }}
  </h1>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/helpers/variables';
@import 'src/assets/styles/helpers/mixin';

.page-title {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.3rem;
  color: $titleDark;
}

@media (max-width: $screen-xs-max){
 .page-title{
	 font-size: 20px;
	 line-height: 24px;
 }
}
</style>
