<template>
  <div
    class="callback"
    v-loading="loading"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :hide-required-asterisk="true"
      @submit.native.prevent="submitForm('form')"
    >
      <el-form-item label="Тема обращения" prop="subject">
        <el-input
          v-model="form.subject"
          placeholder="Проблемы с чатом"
          type="text"
        />
      </el-form-item>

      <el-form-item label="Сообщение" prop="body">
        <el-input
          v-model="form.body"
          placeholder="Сообщения не всегда доходят вовремя. Как это можно исправить?"
          type="textarea"
          :rows="7" />
      </el-form-item>

      <div class="callback__footer">
        <div class="callback__footer-left">
          <el-upload
            class="upload-demo"
            action="#"
            ref="attache"
            :multiple=false
            :limit="1"
            :accept="'image/jpeg, image/png, image/gif'"
            :auto-upload="false"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
          >
            <el-button type="default">Прикрепить файл</el-button>
            <transition name="el-zoom-in-top">
              <div v-if="!fileList.length" class="el-upload__text">Файл не выбран</div>
            </transition>
          </el-upload>
        </div>
        <div class="callback__footer-right">
          <el-button type="primary" native-type="submit">Отправить сообщение</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'Callback',
  data() {
    return {
      loading: false,
      form: {
        subject: '',
        body: '',
        attache: '',
      },
      rules: {
        subject: [
          { required: true, message: 'Введите тему обращения', trigger: ['blur'] },
        ],
        body: [
          { required: true, message: 'Введите Ваш вопрос', trigger: ['blur'] },
        ],
      },
      fileList: [],
    };
  },
  methods: {
    handleRemove(file, fileList) {
      this.fileList = fileList
      this.form.attache = ''
    },
    handleExceed() {
      this.$message.warning(`The limit is 1`)
    },
    handleChange(file, fileList) {
      this.fileList = fileList
      this.form.attache = file.raw
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.send()
        } else {
          return false
        }
      })
    },
    send() {
      this.loading = true

      const formData = new FormData()
      formData.append('subject', this.form.subject)
      formData.append('body', this.form.body)
      if (this.form.attache) {
        formData.append('attache', this.form.attache)
      } else {
        formData.delete('attache')
      }

      axios({ url: process.env.VUE_APP_API_URL + '/feedback', data: formData, method: 'POST', headers: { 'Content-Type': 'multipart/form-data' }})
          .then(() => {
            this.loading = false

            this.form.subject = ''
            this.form.body = ''
            this.$refs.attache.clearFiles();

            this.$notify({
              title: 'Готово',
              message: 'Сообщение отправлено',
              type: 'success',
            });
          })
          .catch(err => {
            console.error(err.response)
            this.loading = false

            this.$notify.error({
              title: 'Ошибка',
              message: err.response.statusText,
            })
          })
    },
  },
}
</script>

<style lang="scss">
@import "src/assets/styles/helpers/mixin";
@import "src/assets/styles/helpers/variables";

.callback {
  &__footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: -2rem;

    @include below($xl) {
      display: block;
    }

    &-left {
      margin-right: 2rem;
      margin-bottom: 2rem;
      max-width: 50%;

      @include below($xl) {
        max-width: none;
      }
    }
  }
}
</style>
