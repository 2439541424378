var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "callback"
  }, [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "hide-required-asterisk": true
    },
    nativeOn: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitForm('form');
      }
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Тема обращения",
      "prop": "subject"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Проблемы с чатом",
      "type": "text"
    },
    model: {
      value: _vm.form.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Сообщение",
      "prop": "body"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Сообщения не всегда доходят вовремя. Как это можно исправить?",
      "type": "textarea",
      "rows": 7
    },
    model: {
      value: _vm.form.body,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "body", $$v);
      },
      expression: "form.body"
    }
  })], 1), _c('div', {
    staticClass: "callback__footer"
  }, [_c('div', {
    staticClass: "callback__footer-left"
  }, [_c('el-upload', {
    ref: "attache",
    staticClass: "upload-demo",
    attrs: {
      "action": "#",
      "multiple": false,
      "limit": 1,
      "accept": 'image/jpeg, image/png, image/gif',
      "auto-upload": false,
      "on-change": _vm.handleChange,
      "on-remove": _vm.handleRemove,
      "on-exceed": _vm.handleExceed
    }
  }, [_c('el-button', {
    attrs: {
      "type": "default"
    }
  }, [_vm._v("Прикрепить файл")]), _c('transition', {
    attrs: {
      "name": "el-zoom-in-top"
    }
  }, [!_vm.fileList.length ? _c('div', {
    staticClass: "el-upload__text"
  }, [_vm._v("Файл не выбран")]) : _vm._e()])], 1)], 1), _c('div', {
    staticClass: "callback__footer-right"
  }, [_c('el-button', {
    attrs: {
      "type": "primary",
      "native-type": "submit"
    }
  }, [_vm._v("Отправить сообщение")])], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }