var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "help-page"
  }, [_c('div', {
    staticClass: "employee__title"
  }, [_c('PageTitle', {
    attrs: {
      "text": "Помощь"
    }
  })], 1), _c('div', {
    staticClass: "help-page__tabs"
  }, [_c('HelpTabs')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }