var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "help-tabs"
  }, [_c('el-tabs', {
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c('el-tab-pane', {
    attrs: {
      "name": "info",
      "label": "Как работать с сервисом"
    }
  }, [_c('FAQ')], 1), _c('el-tab-pane', {
    attrs: {
      "name": "ask",
      "label": "Задать вопрос"
    }
  }, [_c('Callback')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }