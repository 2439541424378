<template>
  <div class="help-tabs">
    <el-tabs v-model="activeName">
      <el-tab-pane name="info" label="Как работать с сервисом">
        <FAQ />
      </el-tab-pane>
      <el-tab-pane name="ask" label="Задать вопрос">
        <Callback />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import FAQ from "./FAQ";
import Callback from "./Callback";

export default {
  name: "HelpTabs",
  components: {
    FAQ,
    Callback
  },
  data() {
    return {
      activeName: "info"
    };
  },
  mounted() {
    if (this.$route.query.tab) {
      this.activeName = this.$route.query.tab;
    }
  }
};
</script>
